<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <h3>Role id: {{ $route.params.id }}</h3>

          <h4>Role name: {{ name }}</h4>

          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
//import axios from 'axios'
export default {
  name: 'Role',
  /*
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  */
  data: () => {
    return {
      name: ''
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted: function() {
    let self = this
    this.$http
      .get('admin/roles/' + self.$route.params.id)
      .then(function(response) {
        self.name = response.data.name
      })
      .catch(function(error) {
        console.error(error)
        self.$router.push({ path: '/login' })
      })
  }
}
</script>
